import React from "react";
import BusSchedule from "./BusSchedule";

function Services() {
  return (
    <div>
      <h2>DVANAEST REDARSTVENIKA - BOROVO NASELJE - SAJMIŠTE</h2>
      <BusSchedule />
    </div>
  );
}

export default Services;
